import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login.vue'
import PasswordResetRequest from '@/views/PasswordResetRequest.vue'
import Verification from '@/views/Verification.vue'
import Invitation from '@/views/Invitation.vue'

// import Search from '@/views/Search.vue'
import Favorites from '@/views/Favorites.vue'

import Documents from '@/views/Documents.vue'
import DocumentEditor from '@/views/DocumentEditor.vue'
import DocumentsBrowser from '@/views/DocumentsBrowser.vue'

// import MySigns from '@/views/MySigns.vue'
import CustomSigns from '@/views/CustomSigns.vue'
import CustomSignEditor from '@/views/CustomSignEditor.vue'

import Lists from '@/views/Lists.vue'
import ListEditor from '@/views/ListEditor.vue'
import ListPlayerSetup from '@/views/ListPlayerSetup.vue'
import ListPlayer from '@/views/ListPlayer.vue'

import FileBrowserView from '@/views/FileBrowserView.vue'

// import Downloads from '@/views/Downloads.vue'

import Settings from '@/views/Settings.vue'
import SettingsOverview from '@/views/settings/SettingsOverview'

import User from '@/views/settings/User'
import UserBilling from '@/views/settings/UserBilling.vue'
// import UserMail from '@/views/settings/UserMail.vue'
import UserPassword from '@/views/settings/UserPassword.vue'
import UserInvoices from '@/views/settings/UserInvoices.vue'
import UserSubscription from '@/views/settings/UserSubscription.vue'

import Team from '@/views/settings/Team'
import TeamOverview from '@/views/settings/TeamOverview'
import TeamCreate from '@/views/settings/TeamCreate'
import TeamMembers from '@/views/settings/TeamMembers'
import TeamInvitations from '@/views/settings/TeamInvitations'
import TeamInvite from '@/views/settings/TeamInvite'
import TeamBilling from '@/views/settings/TeamBilling'

import Help from '@/views/Help.vue'

import SignDetailsView from '@/views/SignDetailsView.vue'
// import SignDetailsPublic from '@/views/SignDetailsPublic'
// import SignDetailsWithSubscription from '@/views/SignDetailsWithSubscription'

import ListDetailsPublic from '@/views/ListDetailsPublic.vue'

// import Debug from '@/views/Debug.vue'

import store from '@/store'

import { publicPath } from '../../vue.config.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: 'login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      unauthenticatedAccess: true,
      unsubscribedAccess: true,
      title: 'Anmelden oder neues Konto erstellen'
    }
  },
  {
    path: '/passwort-zuruecksetzen',
    name: 'password-reset-request',
    component: PasswordResetRequest,
    meta: {
      unauthenticatedAccess: true,
      unsubscribedAccess: true,
      title: 'Password vergessen'
    }
  },
  {
    path: '/bestaetigung/:verificationId/:action/:token',
    name: 'verification',
    component: Verification,
    meta: { unauthenticatedAccess: true, unsubscribedAccess: true }
  },
  {
    path: '/einladung/:invitationId/:invitationToken',
    name: 'invitation',
    component: Invitation,
    meta: { unauthenticatedAccess: true, unsubscribedAccess: true }
  },
  {
    path: '/anmeldung',
    component: () => import(/* webpackChunkName: "registration" */ '@/views/registration/RegistrationLayout.vue'),
    meta: { unauthenticatedAccess: true, unsubscribedAccess: true },
    children: [
      { path: '', redirect: 'neues-konto' },
      {
        path: 'neues-konto',
        name: 'signup',
        component: () => import(/* webpackChunkName: "registration" */ '../views/registration/Signup.vue'),
        meta: {
          unauthenticatedAccess: true,
          unsubscribedAccess: true,
          title: 'Neues Konto erstellen'
        }
      },
      {
        path: 'bestellung',
        name: 'checkout',
        component: () => import(/* webpackChunkName: "registration" */ '../views/registration/Checkout.vue'),
        meta: {
          unauthenticatedAccess: false,
          unsubscribedAccess: true,
          title: 'Lizenz erwerben'
        }
      }
    ]
  },
  {
    path: '/bestellung-erfolgreich',
    name: 'order-completed',
    component: () => import(/* webpackChunkName: "registration" */ '../views/registration/OrderCompleted.vue'),
    meta: {
      unauthenticatedAccess: false,
      unsubscribedAccess: true,
      title: 'Bestellung erfolgreich!'
    }
  },
  {
    path: '/gebaerden',
    component: () => import(/* webpackChunkName: "main-app" */ '@/views/AppLayout.vue'),
    meta: { unauthenticatedAccess: true, unsubscribedAccess: true },
    children: [
      {
        path: ':slug?',
        name: 'sign-details-public',
        component: SignDetailsView,
        meta: { unauthenticatedAccess: true, unsubscribedAccess: true }
      }
    ],
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthenticated'] && store.getters['auth/user']?.hasActiveSubscription) {
        const slug = to.params.slug ? to.params.slug : ''
        next(`/app/gebaerden/${slug}`)
      } else {
        next()
      }
    }
  },
  {
    path: '/lernliste',
    component: () => import(/* webpackChunkName: "main-app" */ '@/views/AppLayout.vue'),
    meta: { unauthenticatedAccess: true, unsubscribedAccess: true },
    children: [
      {
        path: 'ueberblick/:shareKey?',
        name: 'list-overview-public',
        component: ListDetailsPublic,
        meta: { unauthenticatedAccess: true, unsubscribedAccess: true },
        beforeEnter: (to, from, next) => {
          if (store.getters['auth/isAuthenticated'] && store.getters['auth/user']?.hasActiveSubscription) {
            const id = to.params.id
            const shareKey = to.params.shareKey

            if (id) {
              next({ name: 'list-editor', params: { id } })
            } else if (shareKey) {
              next({ name: 'list-editor-shared', params: { shareKey } })
            } else {
              next()
            }
          } else {
            next()
          }
        }
      },
      {
        path: ':shareKey?',
        name: 'list-player-public',
        component: ListPlayer,
        meta: { unauthenticatedAccess: true, unsubscribedAccess: true }
      }
    ]
  },
  {
    path: '/app',
    component: () => import(/* webpackChunkName: "main-app" */ '@/views/AppLayout.vue'),
    meta: { unsubscribedAccess: true },
    children: [
      { path: '', redirect: 'suche' },
      {
        path: 'suche',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
        meta: {
          title: 'Gebärden durchsuchen'
        }
      },
      {
        path: 'favoriten',
        name: 'favorites',
        component: Favorites,
        meta: {
          title: 'Deine Favoriten'
        }
      },
      {
        path: 'lernlisten',
        name: 'lists',
        component: Lists,
        meta: {
          title: 'Lernlisten'
        }
      },
      {
        path: 'lernliste-bearbeiten/:id?',
        name: 'list-editor',
        component: ListEditor,
        meta: {
          title: 'Lernliste bearbeiten'
        }
      },
      {
        path: 'geteilte-lernliste/:shareKey?',
        name: 'list-editor-shared',
        component: ListEditor,
        meta: {
          title: 'Geteilte Lernliste'
        }
      },
      {
        path: 'lernliste/:id/start',
        name: 'list-player-setup',
        component: ListPlayerSetup,
        meta: {
          title: 'Lernliste abspielen'
        }
      },
      {
        path: 'lernliste/:id',
        name: 'list-player',
        component: ListPlayer,
        meta: {
          title: 'Lernliste abspielen'
        }
      },
      {
        path: 'geteilte-lernliste/:shareKey/start',
        name: 'list-player-setup-shared',
        component: ListPlayerSetup,
        meta: {
          title: 'Geteilte Lernliste abspielen'
        }
      },
      {
        path: 'geteilte-lernliste/:shareKey',
        name: 'list-player-shared',
        component: ListPlayer,
        meta: {
          title: 'Geteilte Lernliste abspielen'
        }
      },
      {
        path: 'dokumente',
        name: 'documents',
        component: Documents,
        meta: {
          title: 'Druckvorlagen'
        }
      },
      {
        path: 'dokument/:id?',
        name: 'document-editor',
        component: DocumentEditor,
        meta: {
          title: 'Druckvorlage bearbeiten'
        }
      },
      {
        path: 'organisieren',
        name: 'file-browser',
        component: FileBrowserView,
        meta: {
          title: 'Organisieren'
        }
      },
      {
        path: 'meine-dokumente',
        name: 'documents-browser',
        component: DocumentsBrowser,
        meta: {
          title: 'Deine Dokumente'
        }
      },

      {
        path: 'eigene-gebaerden',
        name: 'custom-signs',
        component: CustomSigns,
        meta: {
          title: 'Eigene Gebärden'
        }
      },
      { path: 'eigene-gebaerden/:id?', name: 'custom-sign-editor', component: CustomSignEditor },
      {
        path: 'eigene-gebaerde/:id?',
        redirect: (to) => {
          return {
            name: 'custom-sign-editor',
            params: to.params,
            query: to.query
          }
        }
      },

      // { path: 'downloads', name: 'downloads', component: Downloads },

      {
        path: 'einstellungen',
        component: Settings,
        meta: { unsubscribedAccess: true, breadcrumbName: 'Einstellungen', title: 'Einstellungen' },
        children: [
          { path: '', name: 'settings', component: SettingsOverview, meta: { unsubscribedAccess: true } },
          {
            path: 'konto',
            component: User,
            name: 'user-settings',
            meta: { unsubscribedAccess: true, breadcrumbName: 'Benutzerkonto', title: 'Konto-Einstellungen' },
            children: [
              { path: '', redirect: { name: 'user-billing' } },
              {
                path: 'rechnungsadresse',
                name: 'user-billing',
                component: UserBilling,
                meta: {
                  unsubscribedAccess: true,
                  breadcrumbName: 'Rechnungsadresse',
                  title: 'Rechnungsadresse',
                  restrictedToRoles: ['customers']
                }
              },
              {
                path: 'passwort-aendern',
                name: 'user-password',
                component: UserPassword,
                meta: { unsubscribedAccess: true, breadcrumbName: 'Passwort ändern', title: 'Passwort ändern' }
              },
              // { path: 'email-aendern', name: 'user-mail', component: UserMail, meta: { unsubscribedAccess: true, breadcrumbName: 'E-Mail ändern' } },
              {
                path: 'abo',
                name: 'user-subscription',
                component: UserSubscription,
                meta: {
                  unsubscribedAccess: true,
                  breadcrumbName: 'Lizenz',
                  title: 'Deine Lizenz',
                  restrictedToRoles: ['customers']
                }
              },
              {
                path: 'rechnungen',
                name: 'user-invoices',
                component: UserInvoices,
                meta: {
                  unsubscribedAccess: true,
                  breadcrumbName: 'Rechnungen',
                  title: 'Deine Rechnungen',
                  restrictedToRoles: ['customers']
                }
              }
            ]
          },
          {
            path: 'team',
            component: Team,
            children: [
              {
                path: 'uebersicht',
                name: 'team-overview',
                component: TeamOverview,
                meta: { restrictedToTeamRoles: ['member', 'manager', 'owner'] }
              },
              {
                path: 'mitglieder',
                name: 'team-members',
                component: TeamMembers,
                meta: { restrictedToTeamRoles: ['manager', 'owner'] }
              },
              {
                path: 'einladungen',
                name: 'team-invitations',
                component: TeamInvitations,
                meta: { restrictedToTeamRoles: ['manager', 'owner'] }
              },
              {
                path: 'einladen',
                name: 'team-invite',
                component: TeamInvite,
                meta: { restrictedToTeamRoles: ['manager', 'owner'] }
              },
              {
                path: 'abrechnung',
                name: 'team-billing',
                component: TeamBilling,
                meta: { restrictedToTeamRoles: ['owner'] }
              }
            ]
          },
          { path: 'team-erstellen', name: 'team-create', component: TeamCreate }
        ]
      },

      {
        path: 'hilfe',
        name: 'help',
        component: Help,
        meta: { unsubscribedAccess: true, title: 'Hilfe & Infos' }
      },

      {
        path: 'gebaerden/:slug',
        name: 'sign-details',
        component: SignDetailsView,
        meta: { unauthenticatedAccess: false, unsubscribedAccess: true },
        beforeEnter: (to, from, next) => {
          if (store.getters['auth/isAuthenticated'] && store.getters['auth/user']?.hasActiveSubscription) {
            next()
          } else {
            next({
              name: 'sign-details-public',
              params: { slug: to.params.slug }
            })
          }
        }
      }
      // { path: 'debug', name: 'debug', component: Debug, meta: { unsubscribedAccess: true } }
    ]
  },
  // Fallthrough
  {
    path: '*',
    redirect: (to) => {
      console.log('Redirect to ', to)
      if (store.getters['auth/isAuthenticated']) {
        return { name: 'search' }
      } else {
        return { name: 'login' }
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: publicPath,
  linkActiveClass: 'is-active',
  routes
})

router.afterEach(() => {
  document.querySelectorAll('.modal').forEach((modal) => {
    modal.__vue__?.$vnode?.context?.close()
  })
})

export default router
