<template>
  <div class="document-image" :style="frameStyle" ref="element">
    <!-- Actions -->
    <actions-dropdown
      v-if="imageReference != null && dropdown"
      size="is-tiny"
      icon="ellipsis-h"
      :position="dropdownPosition"
      :actions="imageActions"
      @action="onImageAction"
      :disabled="!editable"
    />
    <div class="content-wrapper">
      <!-- Image content -->
      <image-component v-if="image != null && imageHidden != true" :image="image" :responsive="responsive" />
      <!-- Add image button -->
      <b-button
        v-if="image == null"
        class="add-button"
        icon-left="plus"
        type="is-primary"
        outlined
        @click="onAddButtonClicked"
      />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { frameStyleMixin } from '@/mixins/document-element-mixin'
import SearchDropdown from '@custom-media/signdigital-web-shared/src/components/SearchDropdown'
import ActionsDropdown from '@/components/ActionsDropdown'
// import ImageComponent from '@/components/ImageComponent'
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent.vue'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import * as Sentry from '@sentry/browser'
import { ensureImageReferenceOwnership } from '@/lib/util/image-ownership'
// import { ImageResolver } from '@/lib/image/image-resolver'

export default {
  mixins: [frameStyleMixin],
  components: {
    ActionsDropdown,
    ImageComponent
  },
  props: {
    frame: {
      required: false,
      type: Object
    },
    imageReference: {
      type: Object
    },
    responsive: {
      type: Boolean,
      default: true
    },
    dropdown: {
      type: Boolean,
      default: true
    },
    dropdownPosition: {
      type: String,
      default: null
    },
    allowedContentTypes: {
      type: Array,
      default: undefined
    },
    allowedSignImageTypes: {
      type: Array,
      default: undefined
    },
    allowedCustomSignTypes: {
      type: Array,
      default: undefined
    },
    editable: {
      type: Boolean,
      default: true
    },
    imageHidden: {
      type: Boolean,
      default: false
    },
    owner: {
      type: String
    },
    ownerType: {
      type: String
    }
  },
  data () {
    return {
      image: null,
      imageActions: [
        { value: 'edit', icon: 'pencil', text: 'Ändern' },
        { value: 'delete', icon: 'trash', text: 'Entfernen' }
      ]
    }
  },
  computed: {},
  watch: {
    imageReference (newValue) {
      if (newValue == null) {
        this.image = null
        return
      }
      if (
        newValue != null &&
        this.image != null &&
        (newValue === this.image.reference || newValue.equalTo(this.image.reference))
      ) {
        // Noo need to reload
        return
      }
      this.image = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: newValue,
        variant: 'default'
      })
    },
    'image.notFound': function (newValue) {
      if (newValue === true) {
        Sentry.addBreadcrumb({
          category: 'document',
          message: 'Resolving image resulted in NotFound error. Removing ' + this.imageReference,
          level: Sentry.Severity.Warning
        })
        this.$emit('not-found', this.image)
        this.$emit('update:image-reference', null)
      }
    }
  },
  mounted () {
    if (this.imageReference) {
      this.image = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: this.imageReference,
        variant: 'default'
      })
    }
  },
  methods: {
    onActionsButtonClicked () {
      this.isActionsTooltipActive = !this.isActionsTooltipActive
    },
    onAddButtonClicked (event) {
      this.presentSelectDialogForSource(event.target)
    },
    onImageClicked (event) {
      this.presentSelectDialogForSource(event.target)
    },
    presentSelectDialogForSource (source) {
      this.isActionsTooltipActive = false
      const SearchDropdownCtrl = Vue.extend(SearchDropdown)
      const el = new SearchDropdownCtrl({
        propsData: {
          target: source,
          minX: 260,
          minY: 70,
          allowedContentTypes: this.allowedContentTypes,
          allowedSignImageTypes: this.allowedSignImageTypes,
          allowedCustomSignTypes: this.allowedCustomSignTypes
        },
        parent: this
      })
      el.$once('selected', async (imageReference) => {
        Sentry.addBreadcrumb({
          category: 'document',
          message: 'User selected new image',
          level: 'info'
        })
        if (imageReference.service === 'images') {
          const { ownerType, owner } = this
          imageReference = await ensureImageReferenceOwnership({ ownerType, owner }, imageReference, this)
          if (imageReference === null) {
            return
          }
        }
        this.$emit('update:image-reference', imageReference)
      })
      el.$once('closed', () => {
        el.$destroy()
        el.$el.parentNode.removeChild(el.$el)
      })
      el.$mount()
    },
    onDeleteButtonClicked () {
      this.isActionsTooltipActive = false
      this.$emit('update:image-reference', null)
    },
    onImageAction (action) {
      switch (action) {
        case 'delete':
          return this.onDeleteButtonClicked()
        case 'edit':
          return this.presentSelectDialogForSource(this.$refs.element)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.document-image {
  position: relative;

  .actions-dropdown {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    opacity: 0;
    transition: opacity linear 0.1s;
    z-index: 10;
  }

  &:hover .actions-dropdown {
    opacity: 1;
  }

  .content-wrapper {
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
    }

    figure.b-image-wrapper.image {
      &::v-deep img {
        object-fit: contain;
      }
    }
  }

  .b-skeleton {
    height: 100%;
  }

  .add-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid $primary;

    &.has-image {
      background-color: transparent;
      border: 0;
      &::v-deep .icon {
        display: none;
      }

      &:hover {
        border: 2px solid $primary;
        background-color: rgba($primary, 0.25);
        &::v-deep .icon {
          color: $primary;
          display: initial;
        }
      }
    }
  }
}
</style>
