/* eslint-disable import/first */

import Vue from 'vue'

// Register composition api before further import calls
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

//
// ─── VENDOR ─────────────────────────────────────────────────────────────────────
//
// JS
import Buefy, { NotificationProgrammatic as Notification } from 'buefy'
Vue.use(Buefy, { defaultIconPack: 'fas' })

import VueScreen from 'vue-screen'
Vue.use(VueScreen, 'bulma')

// VeeValidate
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { required, email, min } from 'vee-validate/dist/rules'
import de from 'vee-validate/dist/locale/de.json'
extend('required', required)
extend('email', email)
extend('min', min)
extend('passwordNumber', {
  message: '{_field_} muss mindestens eine Zahl enthalten',
  validate (value, args) {
    const regex = /[0-9]/g
    return regex.test(value)
  }
})
extend('match', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: '{_field_} stimmt nicht überein'
})
localize(de)
localize({
  de: {
    messages: {
      required: '{_field_} ist erforderlich',
      email: 'Ungültige E-Mail Adresse',
      min: '{_field_} muss mindestens {length} Zeichen lang sein'
    }
  }
})
localize('de')
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// CSS
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/brands.css'
import 'animate.css/animate.min.css'

//
// ─── APP COMPONENTS ─────────────────────────────────────────────────────────────
// JS
import App from './App.vue'
import router from './router'
import store from './store'
import SignDigitalWebShared from '@custom-media/signdigital-web-shared'
import SearchDropdownPlugin from '@/plugins/search-dropdown-plugin'
import FileDialogPlugin from '@/plugins/file-dialog-plugin'

// SCSS
import '@/assets/scss/bulma-customized.scss'

//
// ─── ERROR REPORTING AND TRACING ────────────────────────────────────────────────
//
import * as Sentry from '@sentry/vue'

let tracesSampleRate = Number.parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE)
if (tracesSampleRate == null || isNaN(tracesSampleRate)) {
  tracesSampleRate = 0.01
}
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [
    Sentry.browserTracingIntegration({
      tracingOrigins: [
        'localhost',
        /^\//,
        process.env.VUE_APP_API_HOST,
        process.env.VUE_APP_SEARCH_HOST,
        process.env.VUE_APP_CARGO_HOST
      ]
    })
  ],
  enabled: process.env.VUE_APP_ENVIRONMENT !== 'development',
  environment: process.env.VUE_APP_ENVIRONMENT,
  release: process.env.VUE_APP_SENTRY_RELEASE,
  tracesSampleRate,
  router
})

//
// ─── SETUP ──────────────────────────────────────────────────────────────────────
//
Vue.use(SignDigitalWebShared, { store })
Vue.use(SearchDropdownPlugin)
Vue.use(FileDialogPlugin)

Vue.config.productionTip = false

// Ensure only not authenticated routes can be accessed
router.beforeEach((to, from, next) => {
  const currentUser = store.getters['auth/user']
  const unauthenticatedAccess = to.matched.every((record) => record.meta.unauthenticatedAccess)
  console.log('unauthenticatedAccess === false && !currentUser', unauthenticatedAccess, currentUser)
  if (unauthenticatedAccess === false && !currentUser && to.name !== 'sign-details') {
    return next('/login')
  } else {
    const allowedWithoutEmailVerification = ['login', 'signup', 'verification']
    if (currentUser && !currentUser.isEmailVerified && !allowedWithoutEmailVerification.includes(to.name)) {
      return next('/anmeldung/neues-konto')
    }
    return next()
  }
})

// Restrict routes to active subscription
router.beforeEach((to, from, next) => {
  const currentUser = store.getters['auth/user']
  if (!currentUser) {
    return next()
  }
  const hasSubscription = currentUser.hasSubscription
  console.log('hasSubscription', hasSubscription)
  const hasActiveSubscription = currentUser.hasActiveSubscription
  const isFree = currentUser.billingType === 'free'
  const isAdmin = currentUser.role === 'admins'

  const unsubscribedAccess = to.matched.every((route) => route.meta.unsubscribedAccess)
  if (isFree || isAdmin || unsubscribedAccess || hasActiveSubscription) {
    return next()
  }

  if (hasSubscription === false) {
    Notification.open({ type: 'is-warning', message: 'Du hast keine aktive Lizenz.' })
    return next({ name: 'checkout' })
  } else if (hasActiveSubscription === false) {
    Notification.open({ type: 'is-warning', message: 'Du hast keine aktive Lizenz.' })
    return next({ name: 'user-subscription' })
  }
})

// Restrict routes restricted to certain roles
router.beforeEach((to, from, next) => {
  const currentUser = store.getters['auth/user']
  if (!currentUser) {
    return next()
  }
  const isTryingToAccessRestricedRoute = to.matched.some((route) => route.meta?.restrictedToRoles != null)
  if (!isTryingToAccessRestricedRoute) {
    return next()
  }

  for (const route of to.matched) {
    if (route.meta?.restrictedToRoles != null && !route.meta.restrictedToRoles.includes(currentUser.role)) {
      Notification.open({
        type: 'is-warning',
        message: 'Diese Seite ist nur für folgende Benutzerrollen verfügbar: ' + route.meta.restrictedToRoles.join(', ')
      })
      return next(from)
    }
  }

  return next()
})

// Restrict routes restricted to certain roles
router.beforeEach((to, from, next) => {
  const currentUser = store.getters['auth/user']
  if (!currentUser) {
    return next()
  }
  const isTryingToAccessRestricedRoute = to.matched.some((route) => route.meta?.restrictedToTeamRoles != null)
  if (!isTryingToAccessRestricedRoute) {
    return next()
  }

  const userRole = store.state.session.teamRole

  for (const route of to.matched) {
    if (route.meta?.restrictedToTeamRoles != null && !route.meta.restrictedToTeamRoles.includes(userRole)) {
      Notification.open({
        type: 'is-warning',
        message:
          'Diese Seite ist nur für folgende Team-Rollen verfügbar: ' + route.meta.restrictedToTeamRoles.join(', ')
      })
      return next({ name: 'team-overview' })
    }
  }

  return next()
})

const DEFAULT_TITLE = 'Web-App für Gebärdenvideos, SIGN-Materialien und mehr'
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = (to?.meta?.title ?? DEFAULT_TITLE) + ' - SIGNdigital'
  })
})

async function initApp () {
  try {
    await store.dispatch('auth/authenticate')
  } catch (error) {
    console.error(error)
    window.localStorage.removeItem('feathers-jwt')
  }
  try {
    await store.dispatch('session/fetch')
  } catch (error) {
    console.error(error)
  }
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}

initApp()

// store.dispatch('auth/authenticate')
//   .catch(async (error) => {
//     console.error(error)
//     window.localStorage.removeItem('feathers-jwt')
//     // BUG: Handle notfound error when previous object is removed. Reload?
//   })
//   .then(() => {
//     store.dispatch('session/fetch')

//     await this.$store.dispatch('session/fetch')

//   })
