<template>
  <div class="payment-form">
    <validation-observer slim v-slot="{ invalid }">
      <!-- Payment method switch -->
      <validation-provider name="Zahlungsart" :rules="required ? 'required' : null" v-slot="{ errors, valid }" slim>
        <b-field
          label="Zahlungsart"
          :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
          :message="errors[0]"
        >
          <template v-if="$screen.tablet">
            <!-- Credit card -->
            <b-radio-button
              v-model="paymentMethodType"
              :disabled="!isCardEnabled"
              icon="credit-card"
              native-value="card"
            >
              Kreditkarte
            </b-radio-button>

            <!-- Sepa debit -->
            <b-radio-button v-model="paymentMethodType" native-value="sepa_debit" :disabled="!isSepaEnabled">
              SEPA-Lastschrift
            </b-radio-button>

            <!-- Invoice -->
            <b-radio-button v-model="paymentMethodType" native-value="invoice" :disabled="!isInvoiceEnabled">
              Auf Rechnung
            </b-radio-button>
          </template>
          <template v-else>
            <!-- Small screen layout -->
            <div class="block">
              <!-- Credit card -->
              <b-field>
                <b-radio v-model="paymentMethodType" :disabled="!isCardEnabled" icon="credit-card" native-value="card">
                  Kreditkarte
                </b-radio>
              </b-field>

              <!-- Sepa debit -->
              <b-field>
                <b-radio v-model="paymentMethodType" native-value="sepa_debit" :disabled="!isSepaEnabled">
                  SEPA-Lastschrift
                </b-radio>
              </b-field>

              <!-- Invoice -->
              <b-field>
                <b-radio v-model="paymentMethodType" native-value="invoice" :disabled="!isInvoiceEnabled">
                  Auf Rechnung
                </b-radio>
              </b-field>
            </div>
          </template>
        </b-field>
      </validation-provider>

      <b-message type="is-primary" v-if="paymentMethodType == null">
        <p>Bitte wählen Sie eine dieser Zahlungsarten aus, um eine neue Zahlungsmethode zu hinterlegen.</p>
      </b-message>

      <!-- CREDIT CARD -->
      <div class="card-payment" v-if="paymentMethodType === 'card'">
        <!-- <p class="is-size-7">Wir verwenden den Zahlungsdienstleister Stripe Payments Europe Limited für die Abwicklung von Lastschrift- und Kreditkartenzahlungen. Wenn Sie eine Bezahlung mit Kreditkarte oder Lastschrift vornehmen, werden Daten zur Abwicklung an diesen Dienstleister übermittelt. </p> -->
        <!-- Card holder input -->
        <validation-provider name="Name auf der Kreditkarte" rules="required" v-slot="{ errors, valid }" slim>
          <b-field
            label="Name auf der Kreditkarte"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
          >
            <b-input type="text" v-model="cardOwner" />
          </b-field>
        </validation-provider>

        <validation-provider name="Kreditkarten-Nummer" :rules="{ required: { allowFalse: false } }">
          <card-input ref="cardInput" v-model="isStripeElementValid" :stripe-elements="stripeElements" />
        </validation-provider>
      </div>

      <!-- SEPA -->
      <div class="sepa-payment" v-if="paymentMethodType === 'sepa_debit'">
        <!-- Account holder input -->
        <validation-provider name="Name des Kontoinhabers" rules="required" v-slot="{ errors, valid }" slim>
          <b-field
            label="Name des Kontoinhabers"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
          >
            <b-input type="text" v-model="accountOwner" />
          </b-field>
        </validation-provider>

        <!-- Iban input-->
        <validation-provider name="IBAN" :rules="{ required: { allowFalse: false } }" slim>
          <iban-input ref="ibanInput" v-model="isStripeElementValid" :stripe-elements="stripeElements" />
        </validation-provider>

        <!-- Notification email -->
        <validation-provider
          name="E-Mail Adresse für Lastschrift-Ankündigung"
          rules="required|email"
          v-slot="{ errors, valid }"
          slim
        >
          <b-field
            label="E-Mail Adresse für Lastschrift-Ankündigung"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
          >
            <b-input type="text" v-model="notificationEmail" />
          </b-field>
        </validation-provider>

        <!-- Sepa mandate checkbox -->
        <validation-provider name="SEPA Lastschrift-Mandat" :rules="{ required: { allowFalse: false } }">
          <b-field label="Lastschrift-Mandat">
            <b-checkbox v-model="mandateAccepted">
              Ich ermächtige die Heilpädagogische Hilfe Osnabrück („HHO”) gGmbH, Zahlungen von meinem Konto unter
              Zuhilfenahme des Zahlungsanbieters Stripe mittels Lastschrift einzuziehen.<br /><br />
              Zugleich weise ich mein Kreditinstitut an, die von der HHO gGmbH und Stripe auf mein Konto gezogenen
              Lastschriften einzulösen.<br /><br />
              Ich erhalten bis zu zwei Tage vor zukünftigen Lastschrifteinzügen eine Benachrichtigung per E-Mail.<br /><br />
              Ich akzeptiere, dass ich die Kosten der Rückbuchung in Höhe von von 7,00 € zu tragen habe, wenn eine
              rechtmäßig eingezogene Lastschrift auf meine Anweisung oder durch unzureichende Kontodeckung zurückgebucht
              wird.
            </b-checkbox>
          </b-field>
        </validation-provider>
      </div>

      <!-- INVOICE -->
      <div class="invoice" v-if="paymentMethodType === 'invoice'">
        <p>
          <strong
            >Die Zahlung auf Rechnung ist Unternehmen, Organisationen und öffentlichen Einrichtungen
            vorbehalten.</strong
          >
          Wir überprüfen daher jede eingegangene Bestellung, bevor sie angenommen wird.
        </p>
        <br />
        <p>
          Der vollständige Zugang zu SIGNdigital wird erst nach Bearbeitung der Bestellung freigeschaltet. Dies kann
          einige Werktage in Anspruch nehmen.
        </p>
      </div>

      <!-- Submit button -->
      <b-button
        v-if="hasSubmitButton && paymentMethodType != null"
        class="mt-6"
        type="is-primary"
        :disabled="invalid"
        expanded
        @click="onSubmit"
      >
        {{ paymentMethodType === 'invoice' ? 'Mit Zahlung auf Rechnung fortfahren' : 'Zahlungsmethode anlegen' }}
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import CardInput from '@/components/registration/steps/payment/CardInput'
import IbanInput from '@/components/registration/steps/payment/IbanInput'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import feathersClient from '@/feathers-rest-client'
import * as Sentry from '@sentry/browser'

export default {
  components: {
    CardInput,
    IbanInput,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    clientSecret: String,
    customer: Object,
    stripeElements: Object,
    enabledPaymentMethods: Array,
    hasSubmitButton: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paymentMethodType: null,

      setupIntent: null,
      isCreatingSetupIntent: false,
      createSetupIntentError: null,

      isCreatingPaymentMethod: false,
      createPaymentMethodError: null,

      cardOwner: null,
      accountOwner: null,
      notificationEmail: this.$store.getters['auth/user'].email,

      mandateAccepted: false,
      isStripeElementValid: false
    }
  },
  computed: {
    isCardEnabled () {
      return this.enabledPaymentMethods.includes('card')
    },
    isSepaEnabled () {
      return this.enabledPaymentMethods.includes('sepa_debit')
    },
    isInvoiceEnabled () {
      return this.enabledPaymentMethods.includes('invoice')
    },
    isStripePaymentMethod () {
      return this.paymentMethodType === 'card' || this.paymentMethodType === 'sepa_debit'
    },
    currentElement () {
      if (!this.isStripePaymentMethod) {
        return null
      }
      switch (this.paymentMethodType) {
        case 'card':
          return this.$refs.cardInput.element
        case 'sepa_debit':
          return this.$refs.ibanInput.element
      }
      return null
    }
  },
  watch: {
    async customer (newValue) {
      this.cardOwner ??= newValue.name
      this.accountOwner ??= newValue.name
    },
    async paymentMethodType () {
      // TODO: Maybe check setup intent for activeness
      if (this.paymentMethodType === 'sepa_debit' && this.setupIntent == null) {
        this.createSetupIntent()
      }
    }
  },
  methods: {
    /**
     * Create a setup intent for creating a payment method
     */
    async createSetupIntent () {
      this.isCreatingSetupIntent = true
      this.createSetupIntentError = null
      try {
        this.setupIntent = await feathersClient.service('checkout').get(-1, {
          query: { object: 'setup_intent' }
        })
      } catch (error) {
        this.createSetupIntentError = error
        console.error(error)
        Sentry.captureException(error)
      } finally {
        this.isCreatingSetupIntent = false
      }
    },
    // FIXME: Try to cancel unused setup intents on component destroy
    async cancelSetupIntent () {
      if (this.setupIntent == null) {
        return
      }
      // TODO: Error handling
      await feathersClient.remove(this.setupIntent.id, { query: { object: 'setup_intent' } })
      this.setupIntent = null
    },

    async onSubmit () {
      this.isCreatingPaymentMethod = true
      this.createPaymentMethodError = null
      try {
        const paymentMethod = await this.createPaymentMethod()
        this.$emit('submit', paymentMethod)
        return paymentMethod
      } catch (error) {
        this.createPaymentMethodError = error
        Sentry.captureException(error)
        console.error(error)
      } finally {
        this.isCreatingPaymentMethod = false
      }
    },

    async createPaymentMethod () {
      switch (this.paymentMethodType) {
        case 'card':
          return this.createCardPaymentMethod()
        case 'sepa_debit':
          return this.createSepaPaymentMethod()
        case 'invoice':
          return { type: 'invoice' }
      }
    },

    /**
     * Complete the setup intent of a credit card via stripe API
     * This request triggers 3DS authentication if needed
     * @param {Object} data data passed to the 'payment_intend' param of the confirm request
     */
    async createCardPaymentMethod () {
      const { paymentMethod } = await window.stripe.createPaymentMethod({
        type: 'card',
        card: this.$refs.cardInput.element,
        billing_details: {
          name: this.cardOwner
        }
      })
      return paymentMethod
    },

    /**
     * Complete the setup intent of a sepa debit via stripe API
     * This request triggers 3DS authentication if needed
     * @param {Object} data data passed to the 'payment_intend' param of the confirm request
     */
    async createSepaPaymentMethod () {
      const { error, setupIntent } = await window.stripe.confirmSepaDebitSetup(this.setupIntent.client_secret, {
        payment_method: {
          sepa_debit: this.$refs.ibanInput.element,
          billing_details: {
            name: this.accountOwner,
            email: this.notificationEmail
          }
        },
        expand: ['payment_method']
      })
      if (error) {
        throw new Error(error.message)
      }
      this.setupIntent = null
      return setupIntent.payment_method
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.b-checkbox.checkbox {
  align-items: flex-start;
  &::v-deep {
    .check {
      margin-top: 0.1em;
    }
    .control-label {
      padding-left: 1.5em;
    }
  }
}
</style>
