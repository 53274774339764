<template>
  <div class="signup-email-verification">
    <div class="message-wrapper loading" v-if="isPending">
      <b-icon icon="spinner-third" size="is-large" custom-class="fa-spin" />
      <h1 class="title">Die Bestätigung deiner E-Mail Adresse wird durchgeführt..</h1>
    </div>
    <div class="message-wrapper success" v-if="!isPending && !error">
      <b-icon icon="check" size="is-large" />
      <h1 class="title">Bestätigung erfolgreich!</h1>
      <span>Du kannst dieses Fenster jetzt schließen und mit deiner Anmeldung fortfahren.</span>
    </div>
    <div class="message-wrapper error" v-if="!isPending && error">
      <b-icon icon="exclamation-triangle" size="is-large" />
      <h1 class="title">Ups, da ist etwas schiefgelaufen..</h1>
      <p class="subtitle">{{ errorMessage }}</p>
      <p>Du kannst dieses Fenster jetzt schließen</p>
      <!-- TODO: Grund: Expiration, Already verified [...] -->
    </div>
  </div>
</template>

<script>
import { apiMixin } from '@/mixins/api-mixin'
import * as Sentry from '@sentry/browser'
export default {
  mixins: [apiMixin],
  computed: {
    data () {
      return {
        isPending: false,
        error: null
      }
    },
    errorMessage () {
      if (!this.error) {
        return ''
      }
      if (!this.error.response) {
        return this.error
      }
      if (this.error.response.status == null) {
        return '?'
      }
      switch (this.error.response.status) {
        case 400:
        case 401:
          return 'Der Bestätigungs-Link scheint fehlerhaft zu sein. Hast du ihn vielleicht fehlerhaft kopiert?'
        case 403:
          return 'Diesen Bestätigungs-Link hast du bereits verwendet.'
        case 410:
          return 'Der Bestätigungs-Link ist nicht mehr gültig. Bitte fordere einen neuen an.'
      }
      return 'Ein unbekannter Fehler ist aufgetreten (' + this.error.status + '): ' + this.error.message
    }
  },
  mounted () {
    const { verificationId, token } = this.$route.params
    this.verifySignupEmail(verificationId, token)
  },
  methods: {
    async verifySignupEmail (verificationId, token) {
      this.isPending = true
      try {
        await this.completeVerification(verificationId, token)
        this.isPending = false
        this.error = null
      } catch (error) {
        Sentry.captureException(error)
        console.error(error)
        this.isPending = false
        this.error = error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
