<template>
  <div class="password-reset-verification">
    <h1 class="title">Neues Passwort festlegen</h1>

    <b-message v-if="!isPending && error" type="is-danger">
      <h2 class="subtitle">Ups, da ist etwas schiefgelaufen..</h2>
      <p>{{ errorMessage }}</p>
      <p>Du kannst dieses Fenster jetzt schließen</p>
      <b-button icon-left="chevron-left" expanded class="mt-4" tag="router-link" :to="{ name: 'login' }"
        >Zurück zur Anmeldung</b-button
      >
      <!-- TODO: Grund: Expiration, Already verified [...] -->
    </b-message>
    <div class="message-wrapper error" v-if="!isPending && error"></div>
    <b-loading :active="isPending"></b-loading>

    <password-change-form @submit="onSubmit" v-if="!completed && !error" />
    <section v-else-if="completed && !error">
      <p>Das Passwort wurde erfolgreich geändert! Du kannst dich jetzt erneut anmelden.</p>
      <b-button
        icon-left="chevron-left"
        type="is-primary"
        expanded
        class="mt-4"
        tag="router-link"
        :to="{ name: 'login' }"
        >Zur Anmeldung</b-button
      >
    </section>
  </div>
</template>

<script>
import { apiMixin } from '@/mixins/api-mixin'
import PasswordChangeForm from '@/components/PasswordChangeForm.vue'
import * as Sentry from '@sentry/browser'
export default {
  mixins: [apiMixin],
  components: {
    PasswordChangeForm
  },
  data () {
    return {
      isPending: false,
      completed: false,
      error: null
    }
  },
  computed: {
    errorMessage () {
      if (!this.error) {
        return ''
      }
      if (!this.error.response) {
        return this.error
      }
      if (this.error.response.status == null) {
        return '?'
      }
      switch (this.error.response.status) {
        case 400:
        case 401:
          return 'Der Wiederherstellungs-Link scheint fehlerhaft zu sein. Hast du ihn vielleicht fehlerhaft kopiert?'
        case 403:
          return 'Diesen Wiederherstellungs-Link hast du bereits verwendet.'
        case 410:
          return 'Der Wiederherstellungs-Link ist nicht mehr gültig. Bitte fordere einen neuen an.'
      }
      return 'Ein unbekannter Fehler ist aufgetreten (' + this.error.status + '): ' + this.error.message
    }
  },
  mounted () {
    // const { verificationId, token } = this.$route.params
    // TODO: Verify still valid
  },
  methods: {
    async onSubmit (password) {
      const { verificationId, token } = this.$route.params
      this.isPending = true
      try {
        await this.completeVerification(verificationId, token, { password })
        this.error = null
        this.completed = true
      } catch (error) {
        Sentry.captureException(error)
        this.error = error
      } finally {
        this.isPending = false
      }
    }
  }
}
</script>
