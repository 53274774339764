const path = require('path')
// const { sentryWebpackPlugin } = require('@sentry/webpack-plugin')
module.exports = {
  publicPath: process.env.NODE_ENV === 'development' ? '/' : '/app/',
  transpileDependencies: [
    'feathers-vuex',
    '@custom-media/geometry',
    '@custom-media/grid',
    '@custom-media/layout',
    '@custom-media/signdigital-lib',
    '@custom-media/signdigital-web-shared'
  ],
  css: {
    loaderOptions: {
      scss: {
        additionalData: `
          $primary: #53BAC0;
          $secondary: #C58C59;
        `
        // prependData: `
        // $primary: ${process.env.VUE_APP_ENVIRONMENT === 'staging' ? '#53c074' : '#53BAC0'};
        // $secondary: ${process.env.VUE_APP_ENVIRONMENT === 'staging' ? '#c55959' : '#C58C59'};
        // `
      }
    }
  },
  configureWebpack: config => {
    config.resolve.fallback = {
      path: false,
      child_process: false,
      fs: false,
      util: false,
      os: false,
      crypto: false,
      stream: false,
      domain: false,
      async_hooks: false,
      http: false,
      https: false,
      url: false
    }
    if (process.env.NODE_ENV === 'development') {
      // See available sourcemaps:
      // https://webpack.js.org/configuration/devtool/#devtool
      config.devtool = 'eval-source-map'
      // console.log(`NOTICE: vue.config.js directive: ${config.devtool}`)

      config.output.devtoolModuleFilenameTemplate = info => {
        const resPath = path.normalize(info.resourcePath)
        const isVue = resPath.match(/\.vue$/)
        const isGenerated = info.allLoaders

        const generated = `webpack-generated:///${resPath}?${info.hash}`
        const vuesource = `vue-source:///${resPath}`

        return isVue && isGenerated ? generated : vuesource
      }

      config.output.devtoolFallbackModuleFilenameTemplate =
        'webpack:///[resource-path]?[hash]'
    }
  }
}
